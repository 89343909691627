import { Component } from 'react';

import { getClassName } from '@appbaseio/reactivecore/lib/utils/helper';
import types from '@appbaseio/reactivecore/lib/utils/types';
import SkrReactiveList from './SkrReactiveList';

class SkrResultList extends Component {

  static generateQueryOptions = props => SkrReactiveList.generateQueryOptions(props)

  constructor (props) {
    super(props)
    this.state = {result_type: 'trip'}
  }

  onAllData = (results, streamResults, loadMore) => {
    let filteredResults = results;
    if (streamResults.length) {
      const ids = streamResults.map(item => item._id);
      filteredResults = filteredResults.filter(item => !ids.includes(item._id));
    }
    switch (this.props.resultType) {
      case 'departure':
        return this.renderAsDepartureTable(streamResults, filteredResults)
      default:
        return this.renderAsTeaserList(streamResults, filteredResults)
    }
  }

  renderAsTeaserList = (streamResults, filteredResults) => {
    return [...streamResults, ...filteredResults].map(this.renderAsTeaserItem)
  }

  renderAsTeaserItem = (item) => {
    if (item.teaser) {
      return (<div key={item.code} dangerouslySetInnerHTML={{__html: item.teaser}}/>);
    }

    return null;
  }

  renderAsDepartureTable = (streamResults, filteredResults) => {
    return (
      <div className="departures--table">
        <table className="departures--solr">
          <thead className="departures__title-bar">
            <tr>
              <th className="departures__date">Termin<span className="swipe"></span></th>
              <th className="departures__duration">Tage</th>
              <th className="departures__trip">Reise</th>
              <th className="departures__pax">G&auml;ste</th>
              <th className="departures__charge">Preis</th>
              <th className="departures__state">Status</th>
              <th className="departures__cta"></th>
            </tr>
          </thead>
          <tbody>
            {[...streamResults, ...filteredResults].map((item, index) => (
              [this.renderAsTableRow(item, index)]
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  renderAsTableRow = (item, index) => {
    if (item.type !== 'departure') return
    return (
      <tr key={item.code} className={"departures__departure-line " + (index % 2 === 0 ? 'even' : 'odd')}>
        <td className="departures__date">
          <span>
            {new Date(item.departure).toLocaleDateString('de-DE', {month: '2-digit', day: '2-digit' })} – {new Date(item.arrival).toLocaleDateString('de-DE', {year: '2-digit', month: '2-digit', day: '2-digit'})} <span className="departures__date-duration">({item.duration} Tage)</span>
          </span>
          <span className="show-on-mobile">{item.pax_min} – {item.pax_max} Gäste</span>
        </td>
        <td className="departures__duration">
          <span className="departures__item-legend">Tage</span>
          {item.duration}
        </td>
        <td className="departures__trip">
          <a href={item.link + (this.props.vacancy ? 'vakanz/' : '')}>
            <span className="departures__item-legend">Reise</span>
            {item.resolved_title}
          </a>
        </td>
        <td className="departures__pax">
          <span className="departures__item-legend">Gäste</span>
          {item.pax_min} – {item.pax_max}
        </td>
        <td className="departures__charge">
          <span className="departures__item-legend">Preis</span>
          {item.charge_double.toLocaleString('de-DE', {style: 'currency', currency: 'EUR', minimumFractionDigits:0})}
        </td>
        <td className="departures__state" dangerouslySetInnerHTML={{__html: item.status}}></td>
        <td className="departures__cta"><a href={item.link + (this.props.vacancy ? 'vakanz/' : '')} className="button--success is-tiny">zur Reise</a></td>
      </tr>
    )
  }
  render() {
    const { onAllData, ...props } = this.props;
    return (
      <SkrReactiveList
        {...props}
        loadMoreButton={(loadMore, reactiveListProps, state) => {
          return !state.isLoading && reactiveListProps.total !== reactiveListProps.hits.length ? (<p className="text-center"><button onClick={loadMore}>Weitere {reactiveListProps.size} {props.resultType !== 'departure' ? 'Reisen' : 'Reisetermine'} anzeigen</button></p>) : null
        }}
        onAllData={this.onAllData}
      />
    );
  }
}

SkrResultList.propTypes = {
  innerClass: types.style,
  target: types.stringRequired,
  onAllData: types.func,
  resultType: types.stringRequired,
  vacancy: types.bool
};

SkrResultList.defaultProps = {
  target: '_blank',
  resultType: 'trip',
  from: 0,
  size: 20,
  pagination: false,
};

export default SkrResultList
