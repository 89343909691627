import SkrContactList from './SkrContactList'
import SkrFeedbackAggregate from './SkrFeedbackAggregate'
import SkrFeedbackShortList from './SkrFeedbackShortList'

export function renderSidebar(components, result_type, feedbackCount) {
    if (result_type === 'departure') {
        return;
    }
    return (
        <div>
            <SkrContactList {...components.contact} />
            <div className="panel has-divider">
                <h3 className="">7 Gründe für SKR Reisen</h3>
                <ul className="check">
                    <li>Kleine Gruppen</li>
                    <li>Beste Preise – beste Qualität!</li>
                    <li>Begegnungen vor Ort</li>
                    <li>Hervorragende Reiseleitung</li>
                    <li>Landestypische, kleine Hotels</li>
                    <li>Nachhaltig reisen</li>
                    <li>CO₂e-Kompensation für alle Reisen</li>
                </ul>
            </div>
            <h3 hidden={feedbackCount ? 0 : 1}>Feedbacks & Bewertungen</h3>
            {components.feedback_aggregate ? <SkrFeedbackAggregate {...components.feedback_aggregate} /> : null}
            <SkrFeedbackShortList {...components.feedbacks} />
        </div>)
}
