import { Component } from 'react';

import { getClassName } from '@appbaseio/reactivecore/lib/utils/helper';
import types from '@appbaseio/reactivecore/lib/utils/types';
import SkrReactiveList from './SkrReactiveList';

class SkrContactList extends Component {

  static generateQueryOptions = props => SkrReactiveList.generateQueryOptions(props)

  constructor(props) {
    super(props)
  }

  renderAll = (results, streamResults, loadMore) => {
    let filteredResults = results;
    if (streamResults.length) {
      const ids = streamResults.map(item => item._id);
      filteredResults = filteredResults.filter(item => !ids.includes(item._id));
    }

    return this.renderAsTeaserList(streamResults, filteredResults)
  }

  renderAsTeaserList = (streamResults, filteredResults) => {
    return [...streamResults, ...filteredResults].map(this.renderAsTeaserItem)
  }

  renderAsTeaserItem = (item) => {
    if (item.teaser) {
      return (<div key={item.code} title={item.title} dangerouslySetInnerHTML={{__html: item.teaser}}/>);
    }

    return null;
  }

  render() {
    const { pagination, paginationAt, innerClass, onAllData, ...props } = this.props;
    return (
      <SkrReactiveList
        {...props}
        pagination={true}
        paginationAt="bottom"
        innerClass={{'pagination': 'hide'}}
        onAllData={this.renderAll}
      />
    );
  }
}

SkrContactList.propTypes = {
  innerClass: types.style,
  onAllData: types.func,
}

SkrContactList.defaultProps = {
  dataField: 'location_depth',
  sortBy: 'desc',
  size: 1,
  pagination: true,
  pages: 0,
  showResultStats: false,
  onQueryChange (oldQuery, newQuery) {
    if (newQuery === undefined) newQuery = {}
    const originalQuery = newQuery.query || {match_all: {}}

    const queryJSON = JSON.stringify(originalQuery)
    let locationDepth = 0
    if (queryJSON.match('regions.raw')) {
      locationDepth = 3
    } else if (queryJSON.match('countries.raw')) {
      locationDepth = 2
    } else if (queryJSON.match('continents.raw')) {
      locationDepth = 1
    }

    newQuery.query = {
      'bool': {
        'must': [
          {'match': {'type': 'contact'}}, originalQuery, {range: {location_depth: {lte: locationDepth}}}],
      },
    }
  }
}

export default SkrContactList
