import { Component } from 'react';

import { getClassName } from '@appbaseio/reactivecore/lib/utils/helper';
import types from '@appbaseio/reactivecore/lib/utils/types';
import SkrReactiveList from './SkrReactiveList';

class SkrFeedbackShortList extends Component {

  static generateQueryOptions = props => SkrReactiveList.generateQueryOptions(props)

  constructor(props) {
    super(props)
  }

  renderAll = (results, streamResults, loadMore) => {
    let filteredResults = results;
    if (streamResults.length) {
      const ids = streamResults.map(item => item._id);
      filteredResults = filteredResults.filter(item => !ids.includes(item._id));
    }

    return this.renderAsTeaserList(streamResults, filteredResults)
  }

  renderAsTeaserList = (streamResults, filteredResults) => {
    return [...streamResults, ...filteredResults].map(this.renderAsTeaserItem)
  }

  renderAsTeaserItem = (item) => {
    return (<div key={item.code} className="feedback">
        <div className="grid">
          <div className="grid__column is-small-12 is-large-12">
            <div className="grid">
              <div className="grid__column is-small-9 is-large-9">
                <h5 className="feedback__title">"{item.resolved_title}"</h5>
              </div>
              <div className="grid__column is-small-3 is-large-3 feedback__rating">
                <div className={"rating__stars stars-" + item.stars_rating_travel}>
                  <span className="hide"></span>
                </div>
              </div>
              <div className="grid__column is-small-12">
                <small className="feedback__author">
                  <span>{item.pax_name}</span> reiste am {item.departure.replace(/^([0-9]{4})-([0-9]{2})-([0-9]{2})$/, '$3.$2.$1')}, <a href={item.trip_link}>{item.trip_title}</a>
                </small>
                <p className="feedback__text">
                  {item.travel_comment ? item.travel_comment.replace(/(\r\n|\n|\r)/gm," ").replace(/^(.{150}[^\s]*).*/, "$1") : ''}…
                  <a href={item.link} className="feedback__more">[mehr]</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { pagination, paginationAt, innerClass, onAllData, ...props } = this.props;
    return (
      <SkrReactiveList
        {...props}
        pagination={true}
        paginationAt="bottom"
        innerClass={{'pagination': 'hide'}}
        onAllData={this.renderAll}
      />
    );
  }
}

SkrFeedbackShortList.propTypes = {
  innerClass: types.style,
  onAllData: types.func
}

SkrFeedbackShortList.defaultProps = {
  dataField: 'departure',
  sortBy: 'desc',
  size: 2,
  pagination: true,
  pages: 0,
  showResultStats: false,
  onNoResults: '',
  onQueryChange (oldQuery, newQuery) {
    if (newQuery === undefined) newQuery = {}
    const originalQuery = newQuery.query || {match_all: {}}
    newQuery.query = {
      'bool': {
        'must': [
          {
            'has_parent': {
              'parent_type': 'trip', 'query': originalQuery,
            },
          }, {'match': {'type': 'feedback'}}, {'range': {'rating_travel': {'gte': 7}}}],
      },
    }
  }
}

export default SkrFeedbackShortList
