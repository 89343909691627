import { Component } from 'react';
import {
  addComponent,
  removeComponent,
  watchComponent,
  updateQuery,
  setQueryOptions,
  setQueryListener,
} from '@appbaseio/reactivecore/lib/actions';
import { checkPropChange, pushToAndClause } from '@appbaseio/reactivecore/lib/utils/helper';
import types from '@appbaseio/reactivecore/lib/utils/types';
import { connect } from '@appbaseio/reactivesearch/lib/utils';

class SkrFeedbackAggregate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      stats: [],
    }

    this.locked = false;
    this.internalComponent = `${props.componentId}__internal`;
    props.setQueryListener(props.componentId, this.props.onQueryChange, null);
  }

  componentWillMount() {
    this.props.addComponent(this.internalComponent)
    this.props.addComponent(this.props.componentId)
    this.updateQueryOptions(this.props)

    this.setReact(this.props)
  }

  componentWillReceiveProps(nextProps) {
    checkPropChange(this.props.react, nextProps.react, () =>
      this.setReact(nextProps));

    checkPropChange(this.props.options, nextProps.options, () => {
      const { options } = nextProps;
      if (Array.isArray(options)) {
        options.sort((a, b) => {
          if (a.key < b.key) return -1;
          if (a.key > b.key) return 1;
          return 0;
        });
      }
      this.setState({
        stats: options || [],
      });
    });

    checkPropChange(this.props.componentId, nextProps.componentId, () => {
      this.updateQueryOptions(nextProps);
    });
  }

  componentWillUnmount() {
    this.props.removeComponent(this.props.componentId)
    this.props.removeComponent(this.internalComponent)
  }

  setReact = (props) => {
    const { react } = props;
    if (react) {
      const newReact = pushToAndClause(react, this.internalComponent);
      props.watchComponent(props.componentId, newReact);
    } else {
      props.watchComponent(props.componentId, { and: this.internalComponent });
    }
  };

  static defaultQuery = () => ({
    match: { type: "trip" }
  })

  static generateQueryOptions(props) {
    return {
      aggs: {
        5: { "sum" : { "field" : "rating_stars_5" } },
        4: { "sum" : { "field" : "rating_stars_4" } },
        3: { "sum" : { "field" : "rating_stars_3" } },
        2: { "sum" : { "field" : "rating_stars_2" } },
        1: { "sum" : { "field" : "rating_stars_1" } },
      }
    }
  }

  updateQueryOptions = (props) => {
    const queryOptions = SkrFeedbackAggregate.generateQueryOptions(props)
    props.setQueryOptions(props.componentId, queryOptions, false);
    //
    // const query = props.customQuery || SkrFeedbackAggregate.defaultQuery;
    // const value = null;
    //
    // props.updateQuery({
    //   componentId: props.componentId,
    //   query: query(value, props),
    //   value,
    //   label: '',
    //   showFilter: false,
    //   URLParams: false
    // });
  }

  renderRatingDetails(stats) {
    stats = stats.sort((a, b) => (a.key - b.key))

    const sumOfStars = stats.reduce((accumulator, current) => { return accumulator + (current.value * current.key) }, 0)
    const totalCount = stats.reduce((accumulator, current) => { return accumulator + current.value }, 0)
    const average = Math.round((sumOfStars / totalCount) * 10) / 10

    const highestCount = stats.reduce((accumulator, current) => { return Math.max(accumulator, current.value) }, 0)

    const details = [(<li className="rating__details-title" key={0}>{average} von 5 Sternen</li>)]

    return details.concat(stats.reverse().map((stat) => (
      <li key={stat.key}>
        <span className="rating__votes">{stat.value}</span>
        <span className="rating__details-label">{stat.key} Sterne</span>
        <span className="rating__percentage"><span style={{width: Math.round((stat.value / highestCount) * 100000) / 1000 + '%'}}></span></span>
      </li>
    )))
  }

  render() {
    const hasValues = this.props.options.some((item) => (item.value > 0 ))

    if (!hasValues) {
      return null
    }

    return (
      <div>
        <p className="search-results__feedback-average-description">Durchschnittliche Bewertung der angezeigten Reisen</p>
        <ul className="rating__details">
          {this.renderRatingDetails(this.props.options)}
        </ul>
      </div>
    );
  }
}

SkrFeedbackAggregate.propTypes = {
  addComponent: types.funcRequired,
  removeComponent: types.funcRequired,
  setQueryListener: types.funcRequired,
  setQueryOptions: types.funcRequired,
  watchComponent: types.funcRequired,
  options: types.options,
  // component props
  className: types.string,
  componentId: types.stringRequired,
  customQuery: types.func,
  onQueryChange: types.func,
  react: types.react,
  style: types.style,
  title: types.title
}

SkrFeedbackAggregate.defaultProps = {
  className: null,
  style: {},
  onQueryChange(oldQuery, newQuery) {
    if (newQuery === undefined) newQuery = {}
    newQuery.query = newQuery.query || {}
    newQuery.query.bool = newQuery.query.bool || {}
    newQuery.query.bool.must = newQuery.query.bool.must || []
    newQuery.query.match_all && delete newQuery.query.match_all
    newQuery.query.bool.must.push({'match': {'type': 'trip'}})
    newQuery.size = 0
  }
}

const mapStateToProps = (state, props) => {
  const aggs = state.aggregations[props.componentId] || {5: {}, 4: {}, 3:{}, 2:{}, 1:{}};
  return {
    options: [
        { key: 5, value: aggs[5].value || 0 },
        { key: 4, value: aggs[4].value || 0 },
        { key: 3, value: aggs[3].value || 0 },
        { key: 2, value: aggs[2].value || 0 },
        { key: 1, value: aggs[1].value || 0 }
      ]
}};

const mapDispatchtoProps = dispatch => ({
  addComponent: component => dispatch(addComponent(component)),
  removeComponent: component => dispatch(removeComponent(component)),
  setQueryOptions: (component, props, execute) =>
    dispatch(setQueryOptions(component, props, execute)),
  setQueryListener: (component, onQueryChange, beforeQueryChange) =>
    dispatch(setQueryListener(component, onQueryChange, beforeQueryChange)),
  updateQuery: updateQueryObject => dispatch(updateQuery(updateQueryObject)),
  watchComponent: (component, react) => dispatch(watchComponent(component, react)),
});

export default connect(mapStateToProps, mapDispatchtoProps)(SkrFeedbackAggregate)
